:root {
  --blue: #3450c1;
  --blue-transparent: rgba(52, 80, 193, 0);
  --pink: #ffa4c9;
  --mint: #57deb0;
  --yellow: #f4e35a;
  --silver: #eaf5f4;
  --red: #e0384c;

  --background-color: var(--blue);
  --primary-shadow-color: rgba(7, 15, 50, 0.2);
  --text-color: var(--silver);
  --focus-color: var(--mint);
  --focus-background: rgba(87, 222, 176, 0.2);
  --error-color: var(--pink);
  --error-background: rgba(255, 164, 201, 0.2);

  --font-primary: "Gastromond", sans-serif;
  --font-secondary: "Quicksand", sans-serif;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

body,
input,
textarea,
select,
button {
  font-family: var(--font-secondary);
  color: var(--text-color);
}

html,
body {
  height: 100%;
}

body,
#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}
